import { getExploreName } from '../utils/platform';
import { devGenerateVideoToken } from '../utils/util';
import { callProtectedApi } from '../hooks/useApi';

export const config = {
  webEndpoint: 'zoom.us',
  topic: 'Lesson',
  name: `${getExploreName()}-${Math.floor(Math.random() * 1000)}`,
  password: 'abc123',
  signature: '',
  sessionKey: '',
  userIdentity: '',
  // The user role. 1 to specify host or co-host. 0 to specify participant, Participants can join before the host. The session is started when the first user joins. Be sure to use a number type.
  role: 1
};

export const generateVideoToken = async (meetingArgs:any) => callProtectedApi({method: 'GET', resourceType:'zoom', resourceName:'config',
  payload: { ...meetingArgs },
  requestHeaders: {
    'Content-Type': 'application/json',
  }})
