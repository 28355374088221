//import {useNavigate} from "react-router-dom";
import {useContext} from "react";
//import {AuthContext} from "../../providers/Signup/AuthContext";

type APISourceParams = {resourceType: string, resourceName: string, method: 'POST' | 'PUT' | 'GET' | 'DELETE', payload ? : any, requestHeaders?: any}
type APIParams = {headers:any, body?:any, credentials?: RequestCredentials | undefined, method: 'POST' | 'PUT' | 'GET' | 'DELETE',}

export const callProtectedApi = async ({ resourceType, resourceName, method = 'GET', payload, requestHeaders }:APISourceParams) => {
  try {
    const headers = requestHeaders??{
      'Content-Type': 'application/json',
    };
    let getParams = '';
    const params:APIParams = {
      method,
      credentials: 'include',  // Important for including cookies
      headers,
    };
    if (payload) {
      if (method === 'GET') {
        getParams = '?' + new URLSearchParams(payload);
      }
      else {
        params.body = JSON.stringify(payload);
      }
    }
    const response = await fetch(`${process.env.REACT_APP_API ?? ''}/api/${resourceType}/${resourceName}${getParams}`, params);
    if (method === 'GET') {
      console.log('PROTECTED API RESPONSE', response);
      if (response.status === 401) {
        // @ts-ignore
        logout && logout();
        //navigate('/');
      }
      return await response.json();
    }
    return true;
  } catch (e: any) {
    console.log('error', e);
    return false;
  }
}

export const useApi = () => {
  // navigate = useNavigate();
  //const {logout} = useContext(AuthContext);



  return { callProtectedApi };
}
